<template>
  <div class="iss-main-grid">
    <a-modal
      title="商机转移"
      :visible="visible"
      :footer="null"
      centered
      width="880px"
      @cancel="handleCancel"
    >
      <div v-if="showVisible ? handleShowVisible : ''">
        <search-form :items="itemsModal" @fnSearch="handleFnSearchModal" />
        <!--    :allow-selection="{ type: 'radio' }"    -->
        <state-Grid
          ref="gridRef"
          :columns="columnsModal"
          :code="$route.path"
          :url="urlModal"
          :search="searchModal"
          :url-params="{ marketingType: 2, promoteUserId: form.id }"
          :btn-operation="null"
          :scroll="{ x: 800, y: gridHeightModal }"
        >
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
        </state-Grid>
      </div>
    </a-modal>
  </div>
</template>

<script>
// createVNode,
import { reactive, ref, toRefs, watch } from 'vue';
import SearchForm from '@/components/searchForm';
import stateGrid from '@/components/stateGrid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
// import dictionaryApi from '@/api/dictionary.js';
import { message, Modal } from 'ant-design-vue';
// import store from '@/store';
// import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    // AForm: Form,
    // AFormItem: Form.Item,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    // AInputNumber: InputNumber,
    stateGrid,
    Operation,
    AModal: Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const gridRef = ref();
    const state = reactive({
      search: {},
      searchModal: {},
      TagList: [],
      visible: false,
      length: 0,
      showVisible: null,
      gridSelectedRowKeys: [],
      reclaimList: [
        { label: '手动回收', value: '0' },
        { label: '自动回收', value: '1' },
      ],
    });
    const form = reactive({
      id: '',
    });
    // const required = { required: true, message: '不能为空' };
    // const { validateInfos, validate, resetFields } = Form.useForm(form, {
    // reclaim: [required],
    // date: [required],
    // remind: [required],
    // });

    watch(
      () => props.initValue,
      value => {
        if (value) {
          Object.assign(form, props.initValue);
        }
      }
    );
    watch(
      () => props.visible,
      value => {
        state.showVisible = value
      }
    );
    return {
      gridRef,
      // validateInfos,
      ...toRefs(state),
      form,
      itemsModal: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
      ],
      columnsModal: [
        { dataIndex: 'userName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 120, ellipsis: true },
        { dataIndex: 'companyName', title: '公司', ellipsis: true },
        {
          key: 'id',
          fixed: 'right',
          title: '操作',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [],
      options: [
        {
          type: 'transfer',
          label: '转移',
          icon: 'RetweetOutlined',
          permission: 'distribution:transfer',
          fnClick: record => {
            distributionApi
              .marketingTransferMG('', {
                sourcePromoteUserId: form.id,
                targetPromoteUserId: record.id,
              })
              .then(() => {
                message.success('操作成功');
                context.emit('fnOk', false);
              });
          },
        },
      ],
      urlModal: distributionApi.exclusionSelf,
      gridHeightModal: document.body.clientHeight - 416,
      handleShowVisible: () => {
        gridRef.value.refreshGrid();
      },
      handleFnSearchModal: value => {
        const temp = Object.assign({}, value);
        state.searchModal = temp;
      },
      handleCancel: () => {
        context.emit('fnOk', false);
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 161px);
//  overflow-y: auto;
//}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
</style>
