'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/opportunityDistribute/unprocessedPage',
  batchedPageUrl: '/account/opportunityDistribute/page',
  // 已过期分页接口
  expireDistributePage: '/account/opportunityDistribute/expireDistributePage',
  unDistributePage: '/account/opportunityDistribute/unDistributePage',
  clueUrl: '/account/opportunityBind/getSelect',
  marketingUserListUrl:
    '/marketing/marketingPromoteUser/getSelect?marketingType=2',
  // 分发接口
  marketingUserPageUrl: '/marketing/marketingPromoteUser/singlePage',
  // 分页查询推广人员表（过滤的营销人员）
  exclusionSelf: '/marketing/marketingPromoteUser/singlePage/exclusionSelf',

  finishDistributePageUrl:
    '/account/opportunityDistribute/finishDistributePage',
  customDistribute(code, data) {
    return http.post('/account/opportunityDistribute/defineDistribute', data, {
      code,
    });
  },
  // 已分发、已过期重新分发接口
  redistribution(code, data) {
    return http.post('/account/opportunityDistribute/redistribution', data, {
      code,
    });
  },
  // 已过期批量不分发
  expireBatchNoDistribute(code, data) {
    return http.post(
      '/account/opportunityDistribute/expireBatchNoDistribute',
      data,
      {
        code,
      }
    );
  },
  // 批量回收
  recoveryBusiness(code, data) {
    return http.post('/account/opportunityDistribute/recoveryBusiness', data, {
      code,
    });
  },
  // 已完成查询跟进记录和结果
  mgResult(code, params) {
    return http.get('/marketing/marketingFollowUp/page/mgResult', {
      params,
      code,
    });
  },
  // 已完成,状态商机确认
  marketingConfirm(code, data) {
    return http.post('/marketing/marketingGuest/marketingConfirm', data, {
      code,
    });
  },
  // 商家记录回复
  marketingFollowUpReply(code, data) {
    return http.post('/marketing/marketingFollowUpReply', data, {
      code,
    });
  },
  // 商机转移
  marketingTransferMG(code, params) {
    return http.get('/marketing/marketingGuest/marketingTransferMG', {
      params,
      code,
    });
  },

  batchDistribute(code, data) {
    return http.post('/account/opportunityDistribute/batchDistribute', data, {
      code,
    });
  },
  batchNoDistribute(code, data) {
    return http.post('/account/opportunityDistribute/batchNoDistribute', data, {
      code,
    });
  },
  getPage(code, params) {
    return http.get('/authority/user/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/user', data, { code });
  },
  update(code, data) {
    return http.put('/authority/user', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/user', { params, code });
  },
  avatar(code, data) {
    return http.put('/authority/user/avatar', data, { code });
  },
  getUserIdByRoleId(code, roleId) {
    return http.get(`/authority/role/user/${roleId}`, { code });
  },
};
