<template>
  <div class="flex">
    <a-space class="mb-10" :size="12">
      <template v-for="item in btnOperation">
        <template v-if="item.upload">
          <a-upload
            :key="item.type"
            :showUploadList="false"
            :headers="{
              tenant: $store.state.account.tenant,
              token: $store.state.account.token,
              code: item.permission,
            }"
            v-bind="item.upload"
            @change="item.fnChange"
          >
            <a-button>
              <template #icon>
                <component :is="icons[item.icon || 'UploadOutlined']" />
              </template>
              {{ item.label || '上传' }}
            </a-button>
          </a-upload>
        </template>
        <template v-else-if="item.type === 'add'">
          <a-button :key="item.type" type="primary" @click="handleClick(item)">
            <template #icon>
              <component :is="icons[item.icon || 'PlusOutlined']" />
            </template>
            {{ item.label || '新增' }}
          </a-button>
        </template>
        <template v-else-if="item.type === 'delete'">
          <a-button :key="item.type" @click="handleClick(item)">
            <template #icon>
              <component :is="icons[item.icon || 'DeleteOutlined']" />
            </template>
            {{ item.label || '批量删除' }}
          </a-button>
        </template>
        <template v-else-if="item.type === 'file'">
          <a-button
            :key="item.type"
            :type="item.btnType"
            @click="handleClickFile(item)"
          >
            <template #icon>
              <component :is="icons[item.icon]" />
            </template>
            {{ item.label }}
          </a-button>
          <a-modal
            :title="item.label"
            :key="item.type"
            :visible="visible"
            centered
            @cancel="visible = false"
            @ok="handleClickOk(item)"
          >
            <template v-for="child in item.children">
              <template v-if="child.upload">
                <a-row
                  :key="child.type"
                  type="flex"
                  align="center"
                  :class="{ 'mb-32': !child.extra }"
                >
                  <a-col :span="4" class="ta-right">{{ child.label }}：</a-col>
                  <a-col :span="20">
                    <a-upload
                      :key="child.type"
                      :showUploadList="false"
                      :headers="{
                        tenant: $store.state.account.tenant,
                        token: $store.state.account.token,
                        code: child.permission,
                      }"
                      v-bind="child.upload"
                      @change="child.fnChange"
                    >
                      <a-button>
                        <template #icon>
                          <component
                            :is="icons[child.icon || 'UploadOutlined']"
                          />
                        </template>
                        {{ child.label || '上传' }}
                      </a-button>
                    </a-upload>
                  </a-col>
                </a-row>
                <a-row :key="child.type" v-if="child.extra">
                  <a-col :span="4"></a-col>
                  <a-col :span="20" class="iss-extra">
                    <span>{{ child.extra }}</span>
                  </a-col>
                </a-row>
              </template>
              <template v-else>
                <a-row
                  :key="child.type"
                  type="flex"
                  align="middle"
                  :class="{ 'mb-32': !child.extra }"
                >
                  <a-col :span="4" class="ta-right">{{ child.label }}：</a-col>
                  <a-col :span="20">
                    <a-button :type="child.btnType" @click="handleClick(child)">
                      <template #icon>
                        <component :is="icons[child.icon]" />
                      </template>
                      {{ child.label }}
                    </a-button>
                  </a-col>
                </a-row>
                <a-row :key="child.type" v-if="child.extra">
                  <a-col :span="4"></a-col>
                  <a-col :span="20" class="iss-extra">
                    <span>{{ child.extra }}</span>
                  </a-col>
                </a-row>
              </template>
            </template>
          </a-modal>
        </template>
        <template v-else>
          <a-button
            :key="item.type"
            :type="item.btnType"
            @click="handleClick(item)"
          >
            <template #icon>
              <component :is="icons[item.icon]" />
            </template>
            {{ item.label }}
          </a-button>
        </template>
      </template>
    </a-space>
    <a-space> </a-space>
  </div>
  <a-table
    class="iss-grid"
    rowKey="id"
    size="middle"
    v-bind="$attrs"
    :row-selection="rowSelection"
    :data-source="dataSource"
    :pagination="pagingConfig"
    :loading="loading"
  >
    <template v-for="(index, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </a-table>
</template>

<script>
import { createVNode, toRaw } from 'vue';
import { message, Modal, Space, Table, Upload, Row, Col } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'Gird',
  components: {
    ASpace: Space,
    ATable: Table,
    AUpload: Upload,
    ARow: Row,
    ACol: Col,
  },
  props: {
    code: String,
    url: String,
    urlParams: Object,
    search: Object,
    allowSelection: [Boolean, Object],
    btnOperation: Array,
    pagination: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  data() {
    return {
      icons,
      loading: false,
      visible: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      pagingConfig:
        this.pagination === false ? false : this.setPagination(this.pagination),
    };
  },
  created() {
    if (this.url) {
      const { current, pageSize } = this.pagingConfig;
      pageSize ? this.queryListByPaging(current, pageSize) : this.queryList();
    }
  },
  computed: {
    rowSelection() {
      return this.allowSelection
        ? {
            fixed: 'left',
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRowKeys = selectedRowKeys;
              this.selectedRows = selectedRows;
            },
            ...this.allowSelection,
          }
        : null;
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        const { pageSize } = this.pagingConfig;
        pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
      },
    },
  },
  methods: {
    setPagination(data) {
      const { onChange, onShowSizeChange } = data;

      return Object.assign(
        {
          showLessItems: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: 10,
          current: 1,
          size: 'default',
          pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
          showTotal: (total, range) =>
            `共 ${total} 条，当前显示 ${range[0]}-${range[1]} 条`,
        },
        data,
        {
          onChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onChange?.(current, pageSize)
            ),
          onShowSizeChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onShowSizeChange?.(current, pageSize)
            ),
        }
      );
    },
    queryListByPaging(current, size) {
      this.loading = true;
      return this.$http
        .get(this.url, {
          params: { current, size, ...this.urlParams, ...this.search },
          code: this.code,
        })
        .then(({ records, total }) => {
          this.dataSource = records;
          this.$emit('dataChange', this.dataSource.length);
          this.pagingConfig = Object.assign({}, this.pagingConfig, {
            current,
            pageSize: size,
            total: parseInt(total),
          });
        })
        .finally(() => (this.loading = false));
    },
    queryList() {
      this.loading = true;
      return this.$http
        .get(this.url, {
          params: { ...this.urlParams, ...this.search },
          code: this.code,
        })
        .then(data => {
          this.dataSource = data;
          this.$emit('dataChange', this.dataSource.length);
        })
        .finally(() => (this.loading = false));
    },
    refreshGrid(isCurrent) {
      const { current, pageSize } = this.pagingConfig;
      pageSize
        ? this.queryListByPaging(isCurrent ? current : 1, pageSize)
        : this.queryList();
      this.selectedRowKeys = [];
    },
    getDataSource() {
      let dataSource = this.dataSource;
      return dataSource;
    },
    handleClick(item) {
      let permission = item.permission;
      typeof permission === 'string' && (permission = [permission]);
      this.$has(...permission).then(() => {
        const selectedRowKeys = toRaw(this.selectedRowKeys);
        if (item.type === 'delete') {
          const length = selectedRowKeys.length;
          if (length) {
            Modal.confirm({
              title: `确认删除选中的 ${length} 条数据吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: 'danger',
              onOk: () => item.fnClick(selectedRowKeys),
            });
          } else {
            message.info('请先选择数据');
          }
        } else {
          item.fnClick(selectedRowKeys);
        }
      });
    },
    handleClickFile(item) {
      console.log(item);
      this.visible = true;
    },
    handleClickOk(item) {
      console.log(item);
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.iss-extra {
  height: 32px;
  color: #c9cdd4;
  font-size: 14px;
  span {
    position: absolute;
    top: 2px;
  }
}
.iss-grid {
  border-radius: 8px;
  //overflow-y: auto;
  //box-shadow: 0px 3px 10px 0px @primary-3;
  :deep(.ant-table-header) {
    border-radius: 8px 8px 0 0;
  }
  :deep(.ant-table.ant-table-middle) {
    .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-tbody
      > tr
      > td {
      padding: 7px 16px;
      height: 48px;
    }
    .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-body
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-left
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content
      > .ant-table-fixed-right
      > .ant-table-body-outer
      > .ant-table-body-inner
      > table
      > .ant-table-thead
      > tr
      > th,
    .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
    .ant-table-content
      > .ant-table-scroll
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-left
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td,
    .ant-table-fixed-right
      > .ant-table-header
      > table
      > .ant-table-tbody
      > tr
      > td {
      padding: 12px 16px;
      height: 48px;
    }
  }
  :deep(.ant-btn-icon-only) {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
  :deep(.ant-table-pagination.ant-pagination) {
    margin: 20px 20px 0px 0;
    font-size: 12px;
    .ant-pagination-options-quick-jumper input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
    }
    a {
      font-size: 14px;
    }
  }
}
</style>
