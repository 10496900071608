'use strict';

import http from '@/utils/http';

export default {
  promoteUserPageUrl: '/marketing/marketingGuest/page',
  pageUrl: '/marketing/marketingPromoteUser/getBindPage',
  getMarketingUser(code, params) {
    return http.get(`/marketing/marketingPromoteUser/${params}`, { code });
  },
  getMarketingUserList(code, params) {
    return http.get('/marketing/marketingPromoteUser/getSelect', {
      params,
      code,
    });
  },
  exportDetail(code, params) {
    return http.post(
      '/marketing/marketingGuest/exportBindData',
      {},
      { code, params, responseType: 'blob' }
    );
  },
};
