<template>
  <a-modal
    title=""
    :visible="visible"
    :footer="null"
    centered
    width="580px"
    @cancel="handleCancel"
  >
    <div class="rusultLenght">
      <div>
        <div class="recordingTitle">跟进结果</div>
        <div v-if="form.resultList.length !== 0">
          <div
            class="centent"
            v-for="item in form.resultList"
            :key="item.contactId"
          >
            <div class="doneTime">
              <div>完成时间：</div>
              <div>{{ item.followUpTime }}</div>
            </div>
            <div class="doneResults">
              <div>结果类型：</div>
              <div v-if="item.followUpType === 1">无意愿</div>
              <div v-if="item.followUpType === 0">其他</div>
              <div v-if="item.followUpType === 2">已成交</div>
            </div>
            <div class="results">
              <div class="resultsTitle">跟进结果：</div>
              <div class="resultsContent">{{ item.followUpRecord }}</div>
            </div>
            <div class="voucher">
              <div class="voucherTitle">凭证：</div>
              <div class="imgClass" v-if="item.followUpUrl !== ''">
                <img
                  v-for="(imgItem, index) in item.followUpUrl?.split(',')"
                  :key="index"
                  :src="imgItem"
                  class="voucherImg"
                  @click="handleImg(imgItem)"
                />
              </div>
              <div class="imgClass" v-else>无</div>
            </div>
          </div>
        </div>
        <div class="empty" v-else>暂无跟进结果</div>
      </div>

      <div class="recording">
        <div class="recordingTitle">跟进记录</div>
        <div v-if="form.recordList.length !== 0">
          <div
            class="recordingContent"
            v-for="item in form.recordList"
            :key="item.contactId"
          >
            <div class="recordingTime">{{ item.followUpTime }}</div>
            <div class="recordingText">{{ item.followUpRecord }}</div>
            <div class="recordingImage" v-if="item.followUpUrl !== ''">
              <img
                v-for="(imgItem, index) in item.followUpUrl?.split(',')"
                :key="index"
                :src="imgItem"
                class="voucherImg"
                @click="handleImg(imgItem)"
              />
            </div>
            <!--     回复结果如果是空的就不展示回复    v-if="item.marketingFollowUpReplyList?.length !== 0"    -->
            <div
              class="reply"
              v-if="item.marketingFollowUpReplyList?.length !== 0"
            >
              <div class="replyTitle">回复：</div>
              <div
                class="replyContent"
                v-for="replyItem in item.marketingFollowUpReplyList"
                :key="replyItem.id"
              >
                {{ replyItem.replyContent }}
              </div>
            </div>
          </div>
        </div>
        <div class="empty" v-else>暂无跟进记录</div>
      </div>
      <div class="iss-but">
        <!--        todo 按钮需要拿到跟进结果的状态来判断展示   -->
        <a-button type="primary" v-show="!form.complete" @click="handleFnBatch"
          >确认跟进结果</a-button
        >
        <a-button type="primary" v-show="form.complete" disabled
          >已确认跟进结果</a-button
        >
      </div>
    </div>
  </a-modal>
  <!--  图片放大后的布局样式 -->
  <div class="img-preview" :class="{ show: showImage }">
    <div class="img-box" @click="showImage = false">
      <img :src="maxImage" alt="" />
    </div>
  </div>
</template>

<script>
import { Modal, Button, message } from 'ant-design-vue';
import { reactive, toRefs, watch } from 'vue';
import distributionApi from '@/api/distribution';

export default {
  components: {
    AModal: Modal,
    AButton: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      length: 0,
      gridSelectedRowKeys: [],
      showImage: false,
      maxImage: '',
    });
    const form = reactive({
      // 跟进结果列表
      resultList: [],
      // 跟进记录列表
      recordList: [],
      complete: null,
    });
    watch(
      () => props.initValue,
      value => {
        if (value) {
          console.log('value跟进结果', value);
          form.resultList = value.resultList;
          form.recordList = value.recordList;
          form.complete = value.complete;
        }
      }
    );
    const handleImg = image => {
      // 拿到放大图片
      state.maxImage = image;
      state.showImage = true;
    };
    return {
      ...toRefs(state),
      form,
      handleFnBatch: () => {
        distributionApi
          .marketingConfirm('', {
            id: form.recordList[0]?.guestId || form.resultList[0]?.guestId,
          })
          .then(() => {
            message.success('操作成功');
            context.emit('fnOk', false);
            form.complete = !form.complete;
          });
      },
      handleCancel: () => {
        context.emit('update:visible', false);
      },

      handleImg,
    };
  },
};
</script>

<style lang="less" scoped>
.rusultLenght {
  max-height: 700px;
  overflow-y: auto;
  margin-top: 15px;
}
.centent {
  box-shadow: @card-shadow;
}
.centent {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 16px;
  margin: 16px 8px;
}
.doneTime {
  display: flex;
}
.doneResults {
  display: flex;
  margin: 20px 0px;
}
.results {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  .resultsTitle {
    width: 16%;
  }
  .resultsContent {
    text-align: left;
    width: 84%;
  }
}

.voucher {
  display: flex;
  justify-content: space-between;
  .voucherTitle {
    width: 16%;
  }
}
.recordingTitle {
  text-align: center;
  margin: 20px 0px;
  font-size: 16px;
}
.recordingContent {
  box-shadow: @card-shadow;
  padding: 16px;
  margin: 16px 8px;
}
.recordingText {
  margin: 20px 0px;
}
.iss-but {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.reply {
  background: #f2f2f2;
  padding: 10px;
}
.replyContent {
  text-indent: 24px;
}
// todo 设置图片大小
.imgClass {
  width: 448px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .voucherImg {
    width: 25%;
    height: 65px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}
.recordingImage {
  width: 490px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .voucherImg {
    width: 25%;
    height: 65px;
    object-fit: contain;
    margin-bottom: 20px;
  }
}
.empty {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 50px;
  text-align: center;
}

.img-preview {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: #000000;
  z-index: 1001;
  top: 0;
  right: -110vw;
  transition: all 0.3s;
  &.show {
    right: 0;
  }
  .img-box {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    cursor: pointer;
    img {
      height: 100vh;
      -webkit-user-drag: none;
      margin: auto;
    }
  }
}
</style>
