<template>
  <div class="iss-main-grid" ref="mainRef">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </div>
    <a-drawer
      title="归属人"
      placement="right"
      v-model:visible="visible"
      @close="visible = false"
      width="896px"
      :getContainer="mainRef"
    >
      <div class="user-info padding-20">
        <div class="user-info-avatar">
          <img :src="marketingUser.headImgUrl" alt="" />
        </div>
        <div class="user-info-detail">
          <div class="detail-label mr-24">
            <div>归属人</div>
            <div>手机</div>
            <div>公司</div>
          </div>
          <div class="detail-data">
            <div class="overflow" title="">
              {{ marketingUser.userName || '-' }}
            </div>
            <div class="overflow">{{ marketingUser.mobileNum || '-' }}</div>
            <div class="overflow">{{ marketingUser.companyName || '-' }}</div>
          </div>
        </div>
        <div class="user-info-detail">
          <div class="detail-label">
            <div>更新时间</div>
            <div>邮箱</div>
            <div></div>
          </div>
          <div class="detail-data">
            <div class="overflow">{{ marketingUser.updateTime || '-' }}</div>
            <div class="overflow">{{ marketingUser.email || '-' }}</div>
            <div class="overflow">{{ marketingUser.city || ' ' }}</div>
          </div>
        </div>
      </div>
      <div class="user-opportunity padding-20">
        <div>
          <p>商机发展情况</p>
          <div class="opportunity-detail pl-20 pr-20 pt-16 pb-16">
            <div>
              <div>商机总数</div>
              <div>{{ marketingUser.total }}</div>
            </div>
            <div>
              <div>潜客商机</div>
              <div>{{ marketingUser.guestNumber }}</div>
            </div>
            <div>
              <div>监测中商机</div>
              <div>{{ marketingUser.monitorNumber }}</div>
            </div>
            <div>
              <div>已完成商机</div>
              <div>{{ marketingUser.finishNumber }}</div>
            </div>
          </div>
        </div>
        <div class="mt-16">
          <p class="flex">
            <span>商机数据</span>
            <span class="fw-4 export-btn" @click="handleClickExport">
              <ExportOutlined class="mr-4 export-icon" />
              导出列表
            </span>
          </p>
          <grid
            :scroll="{ x: 1000, y: 400 }"
            :url="urlDrawer"
            :search="{ promoteUserId }"
            :columns="columnsDrawer"
            :pagination="{ showTotal: null }"
          >
            <template #optionsDrawer="{ record }">
              <operation :options="optionsDrawer" :record="record" />
            </template>
            <template #status="{ text }">
              <a-tag :color="color[text]">{{ status[text] }}</a-tag>
            </template>
            <template #complete="{ text }">
              <div v-if="text">已确认</div>
              <div v-else>待确认</div>
            </template>
          </grid>
        </div>
      </div>
    </a-drawer>
  </div>
  <state-modal
    v-model:visible="stateVisible"
    :init-value="activeItem"
    @fnOk="handleFnOk"
  />
  <recording
    v-model:visible="recordingVisible"
    :init-value="activeItemValue"
    @fnOk="handleFnOkRecording"
  />
  <result
    v-model:visible="resultVisible"
    :init-value="activeItemValue"
    @fnOk="handleFnOkResult"
  />
</template>

<script>
import Grid from '@/components/grid';
import SearchForm from '@/components/searchForm';
import Operation from '@/components/operation';
import { reactive, ref, toRefs } from 'vue';
import { Drawer, Tag } from 'ant-design-vue';
import opportunityApi from '@/api/opportunity';
import { ExportOutlined } from '@ant-design/icons-vue';
import { downloadExcel } from '@/utils';
import stateModal from '@/views/opportunity/stateModal';
import recording from '@/views/opportunity/recording';
import result from '@/views/opportunity/result';
import distributionApi from '@/api/distribution';

export default {
  name: 'opportunityState',
  components: {
    Grid,
    SearchForm,
    Operation,
    ADrawer: Drawer,
    ATag: Tag,
    ExportOutlined,
    stateModal,
    recording,
    result,
  },
  setup() {
    const gridRef = ref();
    const mainRef = ref();
    const state = reactive({
      stateVisible: false,
      resultVisible: false,
      activeItemValue: {},
      // 调试打开
      recordingVisible: false,
      activeItem: {},
      search: {},
      TagList: [],
      visible: false,
      urlDrawer: opportunityApi.promoteUserPageUrl,
      marketingUser: {},
      promoteUserId: '',
    });

    const getMgResult = record => {
      console.log('record99', record);
      distributionApi
        .mgResult('', {
          guestId: record.id,
        })
        .then(res => {
          console.log('res查看跟进记录', res);
          state.activeItemValue = res;
          state.activeItemValue.complete = record.complete;

          if (record.status === 2) {
            state.resultVisible = true;
          } else {
            state.recordingVisible = true;
          }
        });
    };
    return {
      gridRef,
      mainRef,
      ...toRefs(state),
      status: ['潜客', '监测中', '已完成'],
      color: ['warning', 'processing', 'success'],
      items: [
        {
          key: 'name',
          label: '商机归属人',
        },
      ],
      columns: [
        { dataIndex: 'userName', title: '商机归属人' },
        { dataIndex: 'mobileNum', title: '手机', width: 140 },
        { dataIndex: 'companyName', title: '公司', width: 180, ellipsis: true },
        { dataIndex: 'total', title: '商机数' },
        { dataIndex: 'guestNumber', title: '潜客数' },
        { dataIndex: 'monitorNumber', title: '监测中' },
        { dataIndex: 'finishNumber', title: '已完成' },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          width: 180,
          customRender: ({ text }) => text || '-',
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 110,
          slots: { customRender: 'operation' },
        },
      ],
      columnsDrawer: [
        { dataIndex: 'name', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'email', title: '邮箱', width: 180, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'sourceType',
          title: '来源类型',
          width: 120,
          customRender: ({ text }) => ['留资', '线索池', '会邀'][text],
        },
        { dataIndex: 'source', title: '来源', width: 90, ellipsis: true },
        {
          dataIndex: 'status',
          title: '商机状态',
          width: 90,
          slots: { customRender: 'status' },
        },
        {
          dataIndex: 'complete',
          title: '跟进结果',
          width: 90,
          slots: { customRender: 'complete' },
        },
        {
          key: 'id',
          fixed: 'right',
          title: '操作',
          width: 90,
          slots: { customRender: 'optionsDrawer' },
        },
      ],
      // todo 如果确认了跟进结果，则不支持回复跟进记录，需要增加逻辑判断
      // 查看跟进结果 / 查看跟进记录
      optionsDrawer: [
        {
          type: 'result',
          permission: 'state:result',
          icon: 'CheckCircleOutlined',
          label: '查看跟进结果',
          show: record => {
            if (record.status === 2) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            // 监测中-1 --跟进记录，已完成-2 --跟进结果
            getMgResult(record);
          },
        },
        {
          type: 'transfer',
          permission: 'distribution:transfer',
          icon: 'CheckCircleOutlined',
          label: '查看跟进记录',
          show: record => {
            if (record.status === 1) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            getMgResult(record);
            // state.recordingValue = record
            // state.recordingVisible = true;
          },
        },
      ],
      btnOperation: [],
      options: [
        {
          type: 'detail',
          permission: 'distribution:detail',
          icon: 'EyeOutlined',
          label: '查看',
          fnClick: record => {
            state.promoteUserId = record.id;
            Object.assign(state.marketingUser, record);
            state.visible = true;
          },
        },
        {
          type: 'transfer',
          permission: 'distribution:transfer',
          icon: 'RetweetOutlined',
          label: '商机转移',
          show: item => {
            if (item.total === 0) return false;
          },
          fnClick: record => {
            state.activeItem = record;
            state.stateVisible = true;
          },
        },
      ],
      url: opportunityApi.pageUrl,
      gridHeight: document.body.clientHeight - 317,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleClickExport() {
        opportunityApi
          .exportDetail('', { promoteUserId: state.promoteUserId })
          .then(({ name, data }) => {
            downloadExcel(name, data);
          });
      },
      handleFnOk(value) {
        state.stateVisible = value;
        gridRef.value.refreshGrid();
      },
      handleFnOkRecording() {
        // state.recordingVisible = value;
      },
      handleFnOkResult() {
        // state.resultVisible = value;
        gridRef.value.refreshGrid();
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main-grid {
  :deep(.ant-drawer-body) {
    padding: 0;
  }
}
.user-info {
  background: #f7f8fa;
  height: 120px;
  display: flex;
  .user-info-avatar {
    width: 100px;
    height: 80px;
    padding-right: 20px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .user-info-detail {
    width: 330px;
    height: 80px;
    display: flex;
    margin-right: 14px;
    .detail-label,
    .detail-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: PingFangSC-Regular, PingFang SC;
      & > div {
      }
    }
    .detail-label {
      color: #86909c;
      width: 66px;
    }
    .detail-data {
      color: #1d2129;
      div {
        width: 264px;
      }
    }
  }
}
.user-opportunity {
  font-family: PingFangSC-Regular, PingFang SC;
  p {
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .opportunity-detail {
    display: flex;
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    & > div {
      width: 25%;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div:nth-of-type(1) {
        line-height: 14px;
      }
      & > div:nth-of-type(2) {
        font-size: 16px;
        line-height: 16px;
        color: @primary-color;
        font-weight: 600;
      }
    }
  }
}
.export-icon {
  color: @primary-color;
}
.export-btn {
  cursor: pointer;
}
</style>
