<template>
  <a-modal
    title=""
    :visible="visible"
    :footer="null"
    centered
    width="590px"
    @cancel="handleCancel"
  >
    <div class="max-height">
      <div class="recordingTitle">跟进记录</div>
      <div v-if="form.recordList.length !== 0" class="iss-recording">
        <div class="centent" v-for="item in form.recordList" :key="item.id">
          <div class="recordingTime">{{ item.followUpTime }}</div>
          <div class="recordingText">{{ item.followUpRecord }}</div>
          <div class="recordingImage" v-if="item.followUpUrl !== ''">
            <img
              v-for="(imgItem, index) in item.followUpUrl?.split(',')"
              :key="index"
              :src="imgItem"
              class="voucherImg"
              @click="handleImg(imgItem)"
            />
          </div>
          <!--    todo  回复内容有内容才展示  v-show="form.inputUiState[item.id]"  -->
          <div class="voucher">
            <div
              class="voucherTitle"
              v-show="item.marketingFollowUpReplyList.length !== 0"
            >
              回复内容：
            </div>
            <!--      todo  取值是回复接口返回的响应内容/循环遍历出来的值   -->
            <div
              v-for="(i, index) in item.marketingFollowUpReplyList"
              :key="i.id"
              class="voucherContent"
            >
              <div v-show="index === 0">
                {{ i.replyContent || replyContent }}
              </div>
            </div>
          </div>
          <!--   跟进结果 complete为 true 回复内容则不展示     -->
          <div class="replyReply" v-if="!form.complete">
            <div class="recording" v-show="form.inputUiState[item.id]">
              <a-form layout="vertical">
                <a-form-item label="添加回复" v-bind="validateInfos.reply">
                  <a-textarea
                    v-model:value="form.reply"
                    placeholder="请输入回复内容"
                    show-count
                    :maxlength="500"
                  />
                </a-form-item>
              </a-form>
              <div class="reply-but">
                <!--              回复接口的调取   -->
                <a-button
                  class="mr-16"
                  type="primary"
                  @click="handleReply(item)"
                  >回复</a-button
                >
                <a-button @click="handleCancelReply(item)">取消</a-button>
              </div>
            </div>
            <!--        点击出现回复窗口 item.marketingFollowUpReplyList.length === 0 &&  -->
            <div
              class="reply"
              v-show="
                !form.inputUiState[item.id] &&
                item.marketingFollowUpReplyList.length === 0
              "
            >
              <a-button @click="handleByReply(item)"
                ><MessageOutlined />回复</a-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>暂无跟进记录</div>
    </div>
  </a-modal>
  <!--  图片放大后的布局样式 -->
  <div class="img-preview" :class="{ show: showImage }">
    <div class="img-box" @click="showImage = false">
      <img :src="maxImage" alt="" />
    </div>
  </div>
</template>

<script>
// message
import { Modal, Button, Form, message } from 'ant-design-vue';
import { reactive, toRefs, watch } from 'vue';
import { MessageOutlined } from '@ant-design/icons-vue';
import distributionApi from '@/api/distribution';

export default {
  components: {
    AModal: Modal,
    AButton: Button,
    AForm: Form,
    AFormItem: Form.Item,
    MessageOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      length: 0,
      gridSelectedRowKeys: [],
      showImage: false,
      showReply: false,
      showReplyContent: false,
      maxImage: '',
      replyContent: '',
      replyId: '',
      state: {},
    });
    const form = reactive({
      reply: '',
      // 跟进记录列表
      recordList: [],
      inputUiState: {},
      complete: null,
    });
    const required = { required: true, message: '不能为空' };
    // resetFields
    const { validateInfos, validate } = Form.useForm(form, {
      reply: [required, { max: 500, message: '不可超过500个字符' }],
    });

    const handleImg = image => {
      // 拿到放大图片
      state.maxImage = image;
      state.showImage = true;
    };

    const findIndex = (source, id) => {
      for (let i = 0; i < source.length; i++) {
        const item = source[i];
        if (item.id === id) {
          return i;
        }
      }
      return -1;
    };

    watch(
      () => props.initValue,
      value => {
        if (value) {
          console.log('value跟进记录', value);
          form.recordList = value.recordList;
          form.complete = value.complete;
        }
      }
    );

    watch(
      () => form.recordList,
      () => {
        form.recordList.forEach(item => {
          // 默认值false  !!form.inputUiState[item.id] 是类型转换
          form.inputUiState[item.id] = !!form.inputUiState[item.id];
        });
      }
    );
    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleByReply: item => {
        form.inputUiState[item.id] = true;
        state.replyId = item.id;
        form.reply = '';
      },
      handleFnBatch: () => {},
      handleCancel: () => {
        context.emit('update:visible', false);
      },
      handleReply: item => {
        validate().then(() => {
          distributionApi
            .marketingFollowUpReply('', {
              receiveId: item.promoteUserId,
              followUpId: item.id,
              replyContent: form.reply,
            })
            .then(res => {
              // 根据响应内容拿到下标， 拿到数组下标、通过下标获取数组对应的数据、
              // 往该条数组添加 回复内容的字段, 然后再在页面渲染这个数组
              // followUpId 每条list的id

              form.recordList.forEach((item, index) => {
                if (index === findIndex(form.recordList, res.followUpId)) {
                  item.marketingFollowUpReplyList.push(res);
                }
              });
              // console.log('form.recordList000', form.recordList)
              message.success('操作成功');
              context.emit('fnOk', false);
              state.showReplyContent = true;
              form.inputUiState[item.id] = false;
            });
        });
      },
      handleCancelReply: item => {
        form.inputUiState[item.id] = false;
      },
      handleImg,
    };
  },
};
</script>

<style lang="less" scoped>
.iss-recording {
  max-height: 700px;
  overflow-y: auto;
}
.centent {
  box-shadow: @card-shadow;
}
.centent {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 16px;
  margin: 10px 10px 20px;
}
.doneTime {
  display: flex;
}
.doneResults {
  display: flex;
  margin: 20px 0px;
}
.results {
  display: flex;
  margin: 20px 0px;
}
.voucher {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .voucherTitle {
    width: 15%;
  }
  .voucherContent {
    width: 85%;
    text-align: left;
  }
}
.recordingTitle {
  text-align: center;
  margin: 10px 0px;
  font-size: 16px;
}
.recordingContent {
  box-shadow: @card-shadow;
  padding: 20px;
}
.recordingText {
  margin: 20px 0px;
}
.iss-but {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.reply {
  background: #f2f2f2;
  //padding: 10px;
}
.replyContent {
  text-indent: 24px;
}
// todo 设置图片大小
.recordingImage {
  width: 490px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .voucherImg {
    width: 25%;
    height: 65px;
    object-fit: contain;
    margin-bottom: 20px;
  }
}
.empty {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 50px;
  text-align: center;
}
.img-preview {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: #000000;
  z-index: 1001;
  top: 0;
  right: -110vw;
  transition: all 0.3s;
  &.show {
    right: 0;
  }
  .img-box {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    cursor: pointer;
    img {
      height: 100vh;
      -webkit-user-drag: none;
      margin: auto;
    }
  }
}
.reply-but,
.reply {
  display: flex;
  justify-content: right;
  align-items: center;
}
.replyReply {
  //background: #f2f2f2;
}
.recording {
  background: #f2f2f2;
  padding: 20px;
}
.reply {
  background: #f2f2f2;
  padding: 20px;
}
//.max-height {
//  height: 600px;
//  overflow: hidden;
//}
</style>
